import React from 'react';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import GroupIcon from '@mui/icons-material/Group';
import IconButton from '@mui/material/IconButton';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import RouterOutlinedIcon from '@mui/icons-material/RouterOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoadingSpinner from './components/LoadingSpinner';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import MemoryIcon from '@mui/icons-material/Memory';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, menuClasses } from 'react-pro-sidebar';
import Summary from './pages/Summary';
import Controllers from './pages/Controllers';
import User from './pages/User';
import Registration from './pages/Registration';
import Users from './pages/Users';
import Events from './pages/Events';
import Adresses from './pages/Adresses';
import Authorization from './pages/Authorization';
import Verify from './pages/Verify';
import Reset from './pages/Reset';
import Settings from './pages/Settings';
import { getUserApi, getDevicesApi, getObjectsApi } from './api/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let setAuthorizedEvent = () => {};

const App = () => {
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
  const [theme, setTheme] = React.useState('light');
  const [hasImage, setHasImage] = React.useState(false);
  const [darkToggle, setDarkToggle] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [authorized, setAuthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [objects, setObjects] = React.useState([]);
  const [devices, setDevices] = React.useState([]);
  const [objetsExist, setObjetsExist] = React.useState(false);
  const [devicesExist, setDevicesExist] = React.useState(false);

  React.useEffect(() => {
    setObjetsExist(objects.length > 0);
    setDevicesExist(devices.length > 0);
  }, [objects, devices]);

  const location = useLocation();

  setAuthorizedEvent = (status) => {
    setAuthorized(status);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      console.log('App mounted!');

      getUserApi(
        async (data) => {
          setUser(data);
          setAuthorized(true);
          setLoading(false);
        },
        (err) => {
          setAuthorized(false);
          setLoading(false);
        }
      );
    };

    fetchData();
  }, []);
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const themes = {
    light: {
      sidebar: {
        backgroundColor: '#FFFFFF',
        color: '#010101',
      },
      menu: {
        menuContent: '#fbfcfd',
        icon: '#010101 ',
        hover: {
          backgroundColor: '#FFF3EB',
          color: '#FD6900',
        },
        disabled: {
          color: '#9fb6cf',
        },
      },
    },
    dark: {
      sidebar: {
        backgroundColor: '#0b2948',
        color: '#8ba1b7',
      },
      menu: {
        menuContent: '#082440',
        icon: '#59d0ff',
        hover: {
          backgroundColor: '#00458b',
          color: '#b6c8d9',
        },
        disabled: {
          color: '#3e5e7e',
        },
      },
    },
  };

  const menuItemStyles = {
    root: {
      fontSize: '18px',
      fontWeight: 400,
    },
    icon: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: theme === 'dark' ? '#b6b7b9' : '#082440',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: level === 0 ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1) : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <>
      <div class={`flex h-screen ${darkToggle && 'dark'}`}>
        {!loading ? (
          <>
            {authorized ? (
              <>
                <div class="flex select-none">
                  <Sidebar
                    rootStyles={{
                      color: themes[theme].sidebar.color,
                      borderWidth: 0,
                    }}
                    image={hasImage ? 'https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg' : null}
                    backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
                    width="280px"
                    breakPoint="sm">
                    <Menu menuItemStyles={menuItemStyles}>
                      <MenuItem
                        onClick={() => {
                          collapseSidebar();
                        }}
                        className="mb-10"
                        icon={<MenuRoundedIcon />}>
                        <p class="font-bold">Мира Бизнес</p>
                      </MenuItem>

                      <MenuItem component={<Link to="/" className="link" />} icon={<SpeedIcon />}>
                        Сводка
                      </MenuItem>

                      <MenuItem component={<Link to="users" className="link" />} icon={<GroupIcon />}>
                        Пользователи
                      </MenuItem>

                      <MenuItem component={<Link to="events" className="link" />} icon={<EventNoteIcon />}>
                        События
                      </MenuItem>

                      <MenuItem component={<Link to="adresses" className="link" />} icon={<ApartmentIcon />}>
                        Адреса
                      </MenuItem>

                      <MenuItem component={<Link to="controllers" className="link" />} icon={<MemoryIcon />}>
                        Контроллеры
                      </MenuItem>

                      <MenuItem component={<Link to="settings" className="link" />} icon={<SettingsIcon />}>
                        Настройки
                      </MenuItem>

                      <MenuItem component={<Link to="user" className="link" />} icon={<AccountCircleRoundedIcon />}>
                        Личный кабинет
                      </MenuItem>
                    </Menu>
                  </Sidebar>
                </div>
                <div class="w-full overflow-y-scroll overflow-x-hidden">
                  {broken && (
                    <div class="sticky top-0 m-0 w-full bg-[#FFFFFF] shadow-2xl">
                      <IconButton onClick={() => toggleSidebar()}>
                        <MenuRoundedIcon></MenuRoundedIcon>
                      </IconButton>
                    </div>
                  )}
                  <div>
                    <Routes>
                      <Route path="/" element={<Summary user={user} objects={objects} setObjects={setObjects} />} />
                      <Route path="users" element={<Users user={user} objects={objects} setObjects={setObjects} />} />
                      <Route path="events" element={<Events user={user} objects={objects} setObjects={setObjects} />} />
                      <Route path="adresses" element={<Adresses user={user} objects={objects} setObjects={setObjects} />} />
                      <Route path="controllers" element={<Controllers user={user} objects={objects} setObjects={setObjects} />} />
                      <Route path="settings" element={<Settings />} /> {/* Added this route for Settings page */}
                      <Route path="user" element={<User user={user} setUser={setUser} setAuthorized={setAuthorized} />} />
                      <Route path="registration" element={<Registration />} />
                      <Route path="verify" element={<Verify userLoggedIn={true}></Verify>} />
                      <Route path="resetpass" element={<Reset></Reset>} />
                      <Route path="*" element={<Navigate to={objetsExist ? 'devices' : 'objects'} />} />
                    </Routes>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="w-full">
                  <section>
                    <Routes>
                      <Route path="*" element={<Authorization setUser={setUser} setAuthorized={setAuthorized}></Authorization>} />
                      <Route path="verify" element={<Verify userLoggedIn={false}></Verify>} />
                      <Route path="resetpass" element={<Reset></Reset>} />
                    </Routes>
                  </section>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div class="flex min-h-screen p-1 w-full overflow-y-scroll overflow-x-hidden items-center justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
              <LoadingSpinner error={[]} loading={true} style=""></LoadingSpinner>
            </div>
          </>
        )}
      </div>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export { setAuthorizedEvent };
export default App;
